import React from "react"
import PropTypes from "prop-types"
import style from "./feedback-form.module.css"

class FeedbackForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      submitted: false,
      selectedOption: "",
    }
  }

  onFormSubmit = e => {
    e.preventDefault()
    this.setState({
      submitted: true,
    })

    plausible("Feedback Submit", {// eslint-disable-line no-undef
      props: {
        Answer: this.state.selectedOption,
      },
    })
  }

  renderForm = () => (
    <form className={style.form} onSubmit={this.onFormSubmit}>
      <fieldset>
        <legend>
          <h4>{this.props.title}</h4>
        </legend>
        {this.state.submitted ? (
          <div className={style.success}>
            <h4>Thank you</h4>
            <p>Your feedback will help improve this website.</p>
          </div>
        ) : (
          <>
            <div>
              <input
                type="radio"
                id="yes"
                name="useful"
                value="yes"
                onChange={() => this.setState({ selectedOption: "yes" })}
                checked={this.state.selectedOption === "yes"}
                required
              />
              <label htmlFor="yes">Yes</label>
            </div>
            <div>
              <input
                type="radio"
                id="no"
                name="useful"
                value="no"
                onChange={() => this.setState({ selectedOption: "no" })}
                checked={this.state.selectedOption === "no"}
              />
              <label htmlFor="no">No</label>
            </div>
            <input type="Submit" defaultValue="Submit" />
          </>
        )}
      </fieldset>
    </form>
  )

  render() {
    return this.renderForm()
  }
}

FeedbackForm.propTypes = {
  title: PropTypes.string.isRequired,
}

FeedbackForm.defaultProps = {
  title: ``,
}

export default FeedbackForm
