import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import FeedbackForm from "../components/feedback-form"
import SEO from "../components/seo"

export default ({ data }) => {
  const { title, description, image } = data.markdownRemark.frontmatter
  const { html, excerpt } = data.markdownRemark
  return (
    <Layout>
      <SEO
        title={`${title} — Definition`}
        description={description ? description : excerpt}
        image={image}
      />
      <h1>{title}</h1>

      <div dangerouslySetInnerHTML={{ __html: html }} />

      <FeedbackForm title="Was this helpful?" />
    </Layout>
  )
}

export const query = graphql`
  query getBlogContent($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      id
      frontmatter {
        title
        description
        image
      }
      html
      excerpt
    }
  }
`
